#settings {
    #breadcrumbs:empty {
        display: none;
    }
    .form-horizontal{
        .thumbnail {
            .remove-image {
                top: 4px;
            }
        }
    }
    table {
        table-layout: fixed;
        word-wrap: break-word;
        margin-bottom:0;
        &.table-count {
            > thead > tr > th:first-child {
                padding-left:20px;
            }
            > tbody > tr > td {
                line-height: 2.3;
            }
        }
        tbody > tr > td.link-position {
            width:70px;
            padding:0 10px;
            vertical-align:middle;
            .form-control {
                border-color:transparent;
                background-color:transparent;
                height:100%;
                box-shadow:none;
                text-align:left;
                padding:5px 10px;
            }
        }
        tbody > tr:hover > td.link-position {
            .form-control {
                border-color:$gray-light;
            }
        }
        .sort {
            cursor: pointer;
            &.active {
                color: $brand-primary;
            }
        }
    }
    table.account {
        counter-reset: account;

        td.counter:before {
            counter-increment: account;
            content: counter(account);
        }
    }
    .editor_holder {
        .well {
            border: 1px solid #e3e3e3;
            margin-bottom: 10px;
        }
        .btn-group {
            margin-bottom: 10px;
        }
        textarea {
            min-height: 130px;
        }
    }
    .badge {
        &.json {
            background-color: #0d469d;
            border-radius:3px;
        }
        &.text {
            background-color: #c6cbcf;
            border-radius:3px;
        }
    }
    table.products-price {
        input[type="number"] {
            width: 100px;
        }
    }
    .spinner {
        position: relative;
        height: 50px;
    }
    .expandCategory {
        margin: 8px;
        cursor: pointer;
    }
    .product {
        .asset_tile:nth-child(4n+1) {
            clear: both;
        }
        address {
            .name,
            .attn,
            .street,
            .postal,
            .phone {
                display: block;
            }
        }
        .product-assets {
            .asset_tile{
                 .is-default {
                    color: $gray-light;
                    position: absolute;
                    bottom: 4px;
                    right: 8px;
                    display: none;
                }

                .thumbnail:hover {
                    .is-default {
                        display: block;
                    }
                }

                &.default {
                    .make-default {
                        display: none;
                    }
                    a {
                        border-color: $brand-primary;
                    }
                    .is-default {
                        display: block;
                        color: $brand-primary;
                    }
                }
            }
        }
    }
    .page-section {
        border:1px solid $gray-light;
        border-radius:3px;
        overflow:hidden;
        box-shadow:0 1px 4px rgba(0,0,0,.07);
        .page-section-header {
            position:relative;
            background-color:$gray-x-light;
            padding:10px 15px;
            h4,.form-inline {
                display:inline-block;
                vertical-align:middle;
            }
            h4 {
                height:20px;
                margin:7px auto;
            }
            .form-inline {
                float:left;
                .form-control {
                    width:50px;
                    text-align:left;
                    font-size:18px;
                    padding:0 5px;
                    font-weight:600;
                    border:0;
                    margin-right:10px;
                }
                .btn-group {
                    position:absolute;
                    right:10px;
                }
            }
        }
    }
    .select2-spacer {
        height: 80px;
    }
    .server-settings-list table {
        min-width: 800px;
    }

    .token-checkbox {
        margin-top: 10px;

        span {
            padding-left: 5px;
            font-size: 14px;
        }
    }
}
@media(max-width: 1200px) {
    .server-settings-list {
        overflow-x: scroll;
    }
}

$pysettings-sidebar-width: 273px;

/* The fallback value $pysettings-header-height is needed for the old header,
 * when the unified-header feature is enabled for all portals, this
 * variable can be removed here and in the var() functions below. */
$pysettings-header-height: 70px;

.b-pysettings {
    // Used in Paramount
    --pageHeaderTopPosition: var(--headerHeight, #{$pysettings-header-height});

    display: flex;
    align-items: stretch;
    min-height: calc(100vh - var(--pageHeaderTopPosition));
    margin-bottom: -70px; // compensate `body` padding-bottom
    margin-left: $pysettings-sidebar-width;
}
.b-pysettings__sidebar {
    position: fixed;
    top: var(--pageHeaderTopPosition);
    bottom: 0;
    left: 0;
    z-index: 3; // pysettings content headers are using z-index 2
    flex: none;
    overflow-y: auto;
    width: $pysettings-sidebar-width;
    padding: 16px 0;
    border-right: 1px solid #e8ebed;
    background: #fff; // to cover content if it's scrolls under the menu on the narrow screens
}
.b-pysettings__main {
    flex: 1;
}
.b-pysettings__padded-content {
    box-sizing: border-box;
    padding: 16px 24px;
    min-width: 728px;
    max-width: 912px;
    margin: auto;
    height: 100%;
}
// Make Backbone container take no space if React rendered content.
// Could have use `display: none`, but with this is much easier to debug
// if something went wrong and for some reason both Backbone and React rendered something.
.b-pysettings__react-content:not(:empty) + .b-pysettings__padded-content {
    min-width: initial;
    max-width: initial;
    padding: 0;
    height: auto;
}
