#mass-updater-view {
    top: 140px;
    .upload-verified {
        background-color: green;
    }
}

.mass-updater-dropzone-csv,
.mass-updater-dropzone {
    .placeholder-content {
        padding: 140px 40px;

        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        color: $gray-base;
        .fa {
            display: block;
            margin-bottom: 10px;

            color: $gray-base;
        }
        .btn {
            margin-top: 20px;
        }
    }
    &:hover, &.hover {
        border-color: $brand-primary;

        cursor: pointer;
    }
}
.mass-updater-dropzone-csv{
    .placeholder-content {
        padding: 10px 40px;
    }
}

.thumbnail-placeholder {
    .placeholder-content {
        &.success {
            background-color: $state-success-bg;
            color: $state-success-text;
        }
        &.error {
            background-color: $state-danger-bg;
            color: $state-danger-text;
        }
    }
}

.files-holder {
    line-height: $line-height-base;

    border: 1px solid rgba(0, 0, 0, 0.1);
    -webkit-border-radius: 3px;
       -moz-border-radius: 3px;
            border-radius: 3px;
    background-color: #fff;
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
       -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    .table {
        margin-bottom: 0;
    }
}

.files-holder .table > thead > tr > th {
    border-top: 1px solid $gray-light;
    border-bottom: 1px solid $gray-light;
    background-color: $gray-x-light;
}

.files-holder .table > thead:first-child > tr:first-child > th {
    border-top: 1px solid $gray-light;
}

.files-holder .table > thead > tr > th,
.files-holder .table > tbody > tr > td {
    padding: 15px 15px;
}

.files-holder .table > tbody > tr > td:first-child {
    border-left: 5px solid transparent;
}

.files-holder .table > tbody > tr.row-progress > td:first-child {
    border-left-color: $brand-primary;
}

.files-holder .table > tbody > tr.row-pending > td:first-child {
    border-left-color: $gray-light;
}

.files-holder .table > tbody > tr.row-pending {
    background-color: $gray-x-light;
}

.files-holder .table > tbody > tr.row-pending > td.status {
    font-size: 13px;
    color: $gray-base;
}

.files-holder .table > tbody > tr.row-success > td.status {
    color: $state-success-text;
}

.files-holder .table > tbody > tr.row-failed > td.status,
.files-holder .table > tbody > tr.row-failed span.file-error {
    color: $state-danger-text;
}
.files-holder .table > tbody > tr.row-failed ul.errors li {
    color: $state-danger-text;
}

.files-holder .table > tbody > tr.row-failed > td .file-error {
    display: none;
}

.files-holder .table > tbody > tr.row-failed > td:first-child:hover .file-error,
.files-holder .table > tbody > tr.row-failed:hover > td .file-error {
    display: block;
}

.files-holder .table > tbody > tr.row-failed > td:first-child:hover .file-name,
.files-holder .table > tbody > tr.row-failed:hover > td .file-name {
    display: none;
}

.files-holder .table > tbody > tr.row-error > td:first-child .file-error,
.files-holder .table > tbody > tr.row-error > td .file-error {
    display: block;
}

.files-holder .table > tbody > tr.row-error > td:first-child .file-name,
.files-holder .table > tbody > tr.row-error > td .file-name {
    display: none;
}
.files-holder .table > tbody > tr.row-error > td.status,
.files-holder .table > tbody > tr.row-error span.file-error {
    color: $state-danger-text;
}

.files-header {
    padding: 0 15px;
    h3 {
        margin: 20px 0;
    }
}

td.status .progress {
    width: 130px;
    margin-top: 5px;
}

.label-version {
    display: inline-block;
    padding: 3px 8px;

    font-family: monospace;
    font-size: 12px;
    font-weight: 200;
    line-height: 1.4;
    color: #2c5f7a;

    -webkit-border-radius: 3px;
       -moz-border-radius: 3px;
            border-radius: 3px;
    background-color: #e8f5fd;

    &.error {
        color: #FFF;
        background-color: $state-danger-text;
    }
    &.warning {
        color: $state-warning-text;
        background-color: $state-warning-bg;
    }
    &.success {
        color: $state-success-text;
        background-color: $state-success-bg;
    }
}

#flash-warning {
    color: #734a00;
    border: 1px solid #ffefb7;
    background: #fff8de;
    padding: 5px 10px;
    margin-top: 10px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    text-align: left;

    i {
        color: #ffc600;
        margin-top: 0.2em;
    }
}

.mass-updater-files {
    th,
    td {
        padding: 15px 15px 15px 0 !important;
    }
    th:first-child,
    td:first-child {
        padding: 15px 15px !important;
    }
    th.file-name {
        width: 50%;
    }
    th.file-version {
        width: 18%;
    }
    th.file-size {
        width: 14%;
    }
    th.file-status {
        width: 18%;
        min-width: 145px;
    }
    span.file-name,
    span.file-error {
        display: block;
        margin-left: -5px;
        word-break: break-all;
    }
}
