#compare-metrics {
    line-height: 30px;
    .form-control {
        margin-top: -4px;
        margin-bottom: -2px;
        padding: 0;

        letter-spacing: -0.1px;
    }
    span + span {
        margin-left: 0.25em;
    }
}
.add-metric-button {
    .btn-default {
        margin-top: -4px;
        margin-bottom: -2px;
    }
    .btn-link {
        position: absolute;
        right: 16px;

        line-height: 16px;
        color: $gray-light;

        -webkit-transition: all 0.2s ease-in-out;
             -o-transition: all 0.2s ease-in-out;
                transition: all 0.2s ease-in-out;
        &:hover {
            color: $gray-base;
        }
    }
}

.export-csv {
    i {
        display: none;
    }

    &.polling {
        span {
            display: none;
        }
        i {
            display: inline-block;
        }
    }
}

.delta-positive {
    color: $brand-success;
}
.delta-negative {
    color: $brand-danger;
}

/* side-bar analytics */
.btn-collapse {
    position: absolute;
    top: 42px;
    right: -17px;
    z-index: 4;
    .fa-chevron-left {
        &:before {
            display: block;
            margin-left: -2px;
        }
    }
    .fa-chevron-right {
        &:before {
            display: block;
            margin-right: -2px;
        }
    }
}
.toggle-list {
    -webkit-transition: margin 0.2s ease-out;
            transition: margin 0.2s ease-out;
    + ul {
        overflow: hidden;

        -webkit-transition: 0.2s ease-out;
                transition: 0.2s ease-out;
    }
    &.inactive {
        margin-bottom: -30px;
        .fa {
            @extend .fa-caret-down;
        }
        + ul {
            max-height: 0;
        }
    }
    &.active {
        .fa {
            @extend .fa-caret-up;
        }
        + ul {
            max-height: 500px;
        }
    }
}
