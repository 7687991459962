.editor {
    width: 100%;

    &__header {
        margin: 20px -15px;
        height: 30px;
    }

    &__title {
        text-align: center,;
        font-size: 16px;
        line-height: 30px;
    }

    &__wrapper {
        width: 100%;
        height: calc(100vh - 150px);
        background-color: #fbfbfb;
        box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.05);
        border: 1px solid rgba(0, 0, 0, 0.1);
    }

    &__view {
        position: relative;
        height: 100%;
    }

    .canvas {
        overflow: auto;
        height: -webkit-calc(100% - 200px);
        height: -moz-calc(100% - 200px);
        height: calc(100% - 200px);

        &__view {
            margin: 20px auto;
            position: relative;
            overflow: auto;
            background-color: #fff;
            border: 1px solid rgba(0, 0, 0, 0.1);
            box-shadow: 0 0 6px rgba(0, 0, 0, 0.08);

            img {
                user-drag: none;
                user-select: none;
                -moz-user-select: none;
                -webkit-user-drag: none;
                -webkit-user-select: none;
                -ms-user-select: none;
                position: absolute;
            }

            .preview {
                position: relative;
            }

            textarea {
                position: absolute;

                white-space: pre;
                outline: none;
                -moz-user-select: none;
                -khtml-user-select: none;
                -webkit-user-select: none;
                -ms-user-select: none;
                user-select: none;
                overflow: auto;
                resize: none;

                max-width: 100%;
                max-height: 100%;

                background: transparent;
                border: 1px solid transparent;
                -webkit-border-radius: 5px;
                -moz-border-radius: 5px;
                border-radius: 5px;

                &:hover {
                    border: 1px solid #0af;
                    -webkit-box-shadow: none;
                    -moz-box-shadow: none;
                    box-shadow: none;
                }

                &:focus {
                    border: 1px solid #0af;
                    -webkit-box-shadow: none;
                    -moz-box-shadow: none;
                    box-shadow: none;
                }

                &.noresize {
                    resize: none;
                }
            }

            .ui-resizable-helper {
                border: 1px solid #0af;
                box-shadow: 0 0 6px rgba(0, 128, 255, 0.2);
            }

            .lighter {
                opacity: 0.5;
            }

            .layer {
                cursor: move;

                &.position-locked {
                    cursor: pointer;
                }

                &:focus,
                &.position-locked:focus {
                    cursor: text;
                }
            }
        }
    }

    &__options {
        position: relative;
        height: 100%;
        background-color: #ffffff;
        overflow: auto;
        border: 0;
        box-shadow: 1px 0 0 rgba(0,34,51,0.1), 0 0 6px rgba(0,34,51,0.1);
        z-index: 1;
        padding-left: 20px;
        padding-right: 20px;

        ul {
            margin: 0;
            margin-bottom: 10px;
            padding: 0;
            background-color: #fff;
            border: 1px solid rgba(0,34,51,0.1);
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            border-radius: 5px;
            overflow: hidden;

            li {
                list-style: none;
                padding: 0 5px 0 15px;
                cursor: pointer;
                line-height: 35px;

                .fa {
                    color: rgba(0,34,51,0.5);
                    margin-right: 20px;
                    margin-top: 2px;
                    width: 16px;
                }

                span {
                    color: rgba(0,34,51,0.5);
                    display: inline-block;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    height: 100%;
                    width: calc(100% - 80px);
                    vertical-align: middle;
                }

                button {
                    float: right;
                    width: auto;
                    margin: 0;
                    background: transparent;
                    box-shadow: none;
                    border: none;
                    text-shadow: none;
                    margin-top: 2px;

                    .fa {
                        margin: 0;
                    }

                    &:hover {
                        background-color: transparent;
                    }
                }

                &:nth-child(odd), &:nth-child(odd):hover {
                    background-color: #fff;
                }

                &:nth-child(even), &:nth-child(even):hover {
                    background-color: #F7F8F9;
                }

                &:hover {
                    .fa, span, button .fa {
                        color: rgba(0,34,51,0.8);
                    }
                }

                &.active,
                &.active:hover {
                    background-color: #0af;
                    color: #fff;
                }

                &.active {
                    .fa, span {
                        color: #fff;
                    }

                    button {
                        .fa {
                            color: rgba(255,255,255,.5);
                        }

                        &:hover {
                            background-color: transparent;

                            .fa {
                                color: #fff;
                            }
                        }
                    }

                    &:nth-child(odd), &:nth-child(odd):hover {
                        background-color: #0af;
                    }
                }

                &.ui-sortable-helper {
                    border-radius: 5px;
                    box-shadow: 0 2px 12px rgba(0,34,51,0.1);
                }
            }
        }

        button {
            margin: 5px 0;
            width: 100%;
        }

        .scale {
            margin: 10px 0;
            text-align: center;

            i {
                cursor: pointer;
            }

            input {
                width: 150px;
                display: inline-block;
                margin: 0 10px;
                vertical-align: middle;
            }
        }

        input[type="checkbox"] {
            display: inline-block;
            width: auto;
            height: auto;
            margin-right: 10px;
        }

        label {
            font-weight: normal;
        }

        .size-input {
            input {
                display: inline-block;
                width: 100px;
                height: auto;
                margin-right: 10px;
            }
        }
    }

    &__section {
        margin-bottom: 20px;

        .section-title {
            height: 35px;
            line-height: 35px;
            padding: 5px 0;
            white-space: nowrap;
        }
    }

    &__frames {
        background-color: #ffffff;
        border: 0;
        box-shadow: 0 -1px 0 rgba(0,34,51,0.1);
        height: 200px;
        margin: 0 -15px;

        .frames {
            &__header {
                position: relative;
                width: 100%;
                height: 40px;
                padding: 0 15px;
                border: 0;
                box-shadow: 0 1px 0 rgba(0,34,51,0.1);
                color: rgba(0,34,51,0.5);
                text-align: center;
                z-index: 1;

                h5 {
                    line-height: 24px;
                    white-space: nowrap;
                }

                .fa {
                    font-size: 16px;
                    line-height: 24px;
                    margin-top: 10px;
                    margin-bottom: 10px;
                    margin-left: 15px;
                    color: #aaa;

                    &:focus, &:hover {
                        color: #000;
                    }
                }
            }

            &__list {
                overflow-x: auto;
                overflow-y: hidden;
                position: absolute;
                white-space: nowrap;

                width: 100%;
                height: 160px;
                left: 0;
                right: 0;
                font-size: 0;
                z-index: 0;

                input[type="checkbox"] {
                    display: inline-block;
                    width: auto;
                    height: auto;
                    margin-right: 10px;
                }

                .ui-sortable-placeholder {
                    height: auto !important;
                }

                h3 {
                    margin: 0;
                    padding-left: 50px;
                    line-height: 144px;
                }

                .frame {
                    height: 100%;
                    width: 120px;
                    margin: 0;
                    display: inline-block;
                    font-size: 14px;
                    border-right: 1px solid #F2F4F5;
                    box-shadow: -1px 0 0 #F2F4F5;
                    background-color: #fff;

                    p {
                        width: 100%;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }

                    .image-holder {
                        position: relative;
                        width: 120px;
                        height: 120px;
                        margin: 0;

                        canvas {
                            position: absolute;
                            display: block;
                            margin: auto;
                            max-width: 100px;
                            max-height: 100px;
                            left: 0;
                            right: 0;
                            top: 0;
                            bottom: 0;
                            -webkit-box-shadow: 0 2px 6px rgba(0,34,51,0.05);
                            -moz-box-shadow: 0 2px 6px rgba(0,34,51,0.05);
                            box-shadow: 0 2px 6px rgba(0,34,51,0.05);
                            border: 1px solid rgba(0,34,51,0.1);
                            border-radius: 2px;
                            cursor: pointer;

                            &:hover {
                                border: 1px solid rgba(0,34,51,0.2);
                            }
                        }

                        &.selected canvas, &.selected canvas:hover {
                            border: 1px solid #0af;
                            box-shadow: 0 0 6px rgba(0, 128, 255, 0.4);
                        }

                        .btn-group {
                            position: absolute;
                            display: none;
                        }
                    }

                    .duration-group {
                        width: 100%;
                    }

                    input {
                        background-color: transparent;
                        border: 1px solid transparent;
                        border-top: 1px solid #F2F4F5;
                        height: 38px;

                        &:focus {
                            -webkit-box-shadow: none;
                            -moz-box-shadow: none;
                            box-shadow: none;
                        }
                    }

                    .input-group-addon {
                        background-color: #fff;
                        border: 1px solid transparent;
                        border-top: 1px solid #F2F4F5;
                        height:38px;
                        color: rgba(0,34,51,0.5);
                    }

                    .btn-group {
                        top: 8px;
                        left: 7px;
                        right: 8px;

                        .btn-default {
                            background-color: #fff;
                            border-radius: 3px;
                            border: 0;
                            box-shadow: 0 1px 4px rgba(0,34,51,0.1), 0 0 0 1px rgba(0,34,51,0.1);
                            color: rgba(0,34,51,0.5);

                            &:hover {
                                background-color: #fafafa;
                                border-radius: 3px;
                                border: 0;
                                box-shadow: 0 1px 4px rgba(0,34,51,0.15), 0 0 0 1px rgba(0,34,51,0.1);
                                color: rgba(0,34,51,0.8);
                            }

                            &:last-child {
                                float: right;
                            }
                        }
                    }

                    &:hover .image-holder .btn-group {
                        display: block;
                    }

                    &:first-child:last-child:hover .image-holder .btn-group button:not(.duplicate) {
                        display: none;
                    }

                    &.ui-sortable-helper {
                        border: 0;
                        opacity: .9;
                        box-shadow: 0 2px 12px rgba(0,34,51,0.1);
                        height: 120px !important;
                        border-radius: 5px;
                        cursor: grab;

                        &:focus {
                            cursor: grab;
                        }

                        .input-group,
                        .image-holder .btn-group {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
