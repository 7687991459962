#userrequests,
#usermanagement {
    background-color: #fff;

    thead > tr {
        background-color: #f9f9fa;
    }

    .pre-spinner {
        margin-top: 100px;
    }

    #breadcrumbs {
        height: 50px;
    }
    .table-header {
        h1 {
            width: 270px;
        }
        .search-bar-container {
            width: calc(100% - 990px);
        }
        .search .search-bar-container {
            left: 360px;
        }
        button {
            margin-top: 10px;
            margin-right: 10px;
        }

        .flex-search {
            display: flex;
            gap: 16px;
            align-items: stretch;
            width: 100%;
            white-space: nowrap;
            flex-wrap: wrap;
            justify-content: flex-end;
        
            .search-bar {
                display: flex;
                flex: 1;
                gap: 16px;
                align-items: center;
                min-width: 250px;
        
                .search-bar-container {
                    width: 100%;
                    height: 100%;
                }
        
                input {
                    width: 100%;
                    height: 100%;
                    padding: 8px;
                }
            
        
            }
            
            .actions {
                display: flex;
                flex-direction: row-reverse;
                gap: 10px;
        
                button {
                    margin-top: 0px;
                    margin-right: 0px;
                }

                .fields-control {
                    display: flex;
                    gap: 15px;
                    align-items: center;
                    p {
                        line-height: none;
                        margin: 0px;
                    }
                }
                
                .cockpit-container {
                    margin-top: 0px;

                }
            }
        }
    }

    .filter-button {
      width: 32px;
      height: 36px;
      box-sizing: border-box;
      background-color: #fff;
    }

    #filters th {
        background-color: transparent;
        padding-right: 30px;
        min-width: 140px;

        &.bullet {
            min-width: 20px;
        }

        &[data-field='auditLog'] {
            min-width: 250px;
        }
        &:last-child .options-form {
            left: unset;
            right: 0;
        }

        .clear {
            display: none;
        }

        &.active-filter {
            .clear {
                display: inline-block;
            }
        }

        .filter {
            white-space: nowrap;
        }

        .select2-container {
            .select2-search--inline {
                display: inline-block;
                vertical-align: top;
            }
        }
        .sort-button {
            &.sort {
                .fa-caret-down,
                .fa-caret-up {
                    display: none;
                }
            }
            &.sort-desc {
                .fa-sort,
                .fa-caret-down {
                    display: none;
                }
            }
            &.sort-asc {
                .fa-sort,
                .fa-caret-up {
                    display: none;
                }
            }
        }
        .select2-hidden {
            display: none !important;
        }

        .control-label.input {
            text-align: left;
            .checkbox {
                padding-top: 0;
            }
        }

        .filter-button {
          text-align: center;
          .options-form {
            text-align: left;
          }
        }
    }

    .table {
        tr {
            cursor: pointer;
        }

        td.edit-functions {
            cursor: default;

            .action-button:not(:last-child) {
                margin-right: 15px;
            }
        }

        td.details {
            .expand {
                display: none;
            }
            .collapse {
                display: inline;
            }
            ul {
                max-height: 1000px;
                overflow: hidden;
                transition: max-height 1s;
            }

            &.collapsed {
                ul {
                    max-height: 1.2em;
                }
                .expand {
                    display: inline;
                }
                .collapse {
                    display: none;
                }
            }
        }

        td.bullet {
            vertical-align: middle;
            .bullet-green, .bullet-red {
              margin-top: 5px;
              float: left;
            }
            .bullet-label {
              margin-left: 20px;
            }
        }
    }
}
.cockpit-container {
    margin-top: 5px;
    margin-left: 10px;
}
.fields-control {
    position: relative;
    cursor: pointer;

    p {
        line-height: 40px;
    }

    &.active {
        .options-form,
        .options {
            display: block;
        }
    }
    .options,
    .options-form {
        position: absolute;
        top: 37px;
        right: 0;
        left: auto !important;
        z-index: 20;
        display: none;
        width: 250px;
        min-width: 100%;
        background-color: #fff;
        box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 1px 6px rgba(0, 0, 0, 0.2);

        input {
            width: auto !important;
        }
        select {
            width: 100%;
        }
        .select2-selection__clear {
            position: absolute;
            right: 5px;
            margin-right: 0 !important;
        }
        .select2-selection__rendered {
            padding-right: 20px;
        }
        .checkbox {
            padding-left: 20px;
        }
    }
}
