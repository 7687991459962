
@mixin svg-one-color($color, $background) {
    $color-rgb: rgba(red($color), green($color), blue($color), 0.75);
    /* encoded to make IE working </svg>
    * <svg height='6' width='6' xmlns='http://www.w3.org/2000/svg'>
    *   <circle cx='3' cy='3' r='3' fill='#{$color-rgb}' />
    * </svg>
    */
    background-image: url("data:image/svg+xml,%3Csvg%20height%3D\'6\'%20width%3D\'6\'%20xmlns%3D\'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg\'%3E%3Ccircle%20cx%3D\'3\'%20cy%3D\'3\'%20r%3D\'3\'%20fill%3D\'#{$color-rgb}\'%20%2F%3E%3C%2Fsvg%3E")!important;
    background-repeat: no-repeat!important;
    background-position: center 90%!important;
    background-color: $background;
}
@mixin svg-two-colors($color1, $color2, $background) {
    $color-rgb1: rgba(red($color1), green($color1), blue($color1), 0.75);
    $color-rgb2: rgba(red($color2), green($color2), blue($color2), 0.75);
    /* encoded to make IE working
    * <svg height='6' width='12' xmlns='http://www.w3.org/2000/svg'>
    *   <circle cx='3' cy='3' r='3' fill='#{$color-rgb1}' />
    *   <circle cx='9' cy='3' r='3' fill='#{$color-rgb2}' />
    * </svg>
    */
    background-image: url("data:image/svg+xml,%3Csvg%20height%3D\'6\'%20width%3D\'12\'%20xmlns%3D\'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg\'%3E%3Ccircle%20cx%3D\'3\'%20cy%3D\'3\'%20r%3D\'3\'%20fill%3D\'#{$color-rgb1}\'%20%2F%3E%3Ccircle%20cx%3D\'9\'%20cy%3D\'3\'%20r%3D\'3\'%20fill%3D\'#{$color-rgb2}\'%20%2F%3E%3C%2Fsvg%3E")!important;
    background-repeat: no-repeat!important;
    background-position: center 90%!important;
    background-color: $background;
}
@mixin svg-three-colors($color1, $color2, $color3, $background) {
    $color-rgb1: rgba(red($color1), green($color1), blue($color1), 0.75);
    $color-rgb2: rgba(red($color2), green($color2), blue($color2), 0.75);
    $color-rgb3: rgba(red($color3), green($color3), blue($color3), 0.75);
    /* encoded to make IE working
    * <svg height='6' width='18' xmlns='http://www.w3.org/2000/svg'>
    *   <circle cx='3' cy='3' r='3' fill='#{$color-rgb1}' />
    *   <circle cx='9' cy='3' r='3' fill='#{$color-rgb2}' />
    *   <circle cx='15' cy='3' r='3' fill='#{$color-rgb3}' />
    * </svg>
    */
    background-image: url("data:image/svg+xml,%3Csvg%20height%3D\'6\'%20width%3D\'18\'%20xmlns%3D\'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg\'%3E%3Ccircle%20cx%3D\'3\'%20cy%3D\'3\'%20r%3D\'3\'%20fill%3D\'#{$color-rgb1}\'%20%2F%3E%3Ccircle%20cx%3D\'9\'%20cy%3D\'3\'%20r%3D\'3\'%20fill%3D\'#{$color-rgb2}\'%20%2F%3E%3Ccircle%20cx%3D\'15\'%20cy%3D\'3\'%20r%3D\'3\'%20fill%3D\'#{$color-rgb3}\'%20%2F%3E%3C%2Fsvg%3E")!important;
    background-repeat: no-repeat!important;
    background-position: center 90%!important;
    background-color: $background;
}
