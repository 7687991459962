#templateSettings {
    fieldset {
        border: none;
        padding: 0;
        margin: 0;
    }
    .row {
        margin: 0 -15px;
    }
    .page-header {
        margin-top: 0;
    }
    .settings-preset-picker {
        .list-filter-label {
            display: inline-block;
            margin-left: 10px;
            &:first-of-type {
                margin-left: 0;
            }
        }
        .input-group {
            width: 250px;
            display: inline-block;
            vertical-align: middle;
            .form-control {
                margin-right: 0;
            }
        }
        .form-control {
            display: inline-block;
            width: 200px;
            margin: 0 5px;
            padding: 0 8px 1px;
        }
        .btn-sort-toggle {
            position: relative;
            top: -1px;
            .fa-sort-amount-asc:before {
                content: "\f160";
            }
            &.active {
                .fa-sort-amount-asc:before {
                    content: "\f161";
                }
            }
        }
    }
    .saving:disabled {
        .hidden {
            display: inline-block !important;
        }
    }
    .input-group-addon {
        padding: 6px 8px;
        &[disabled] {
            color: $input-border;
        }
    }
    .btn {
        text-decoration: none;
        &.btn-switch {
            display: block;
        }
    }
    input[type='radio'][disabled] + span,
    input[type='checkbox'][disabled] + span {
        color: $setting-dimmed-text;
    }
}

#podEditor {
    .tooltip-inner {
        word-break: break-word;
    }
    .input-group-btn {
        .fa-fw {
            width: 0.9em;
        }
    }
    .template-header {
        padding: 20px;

        border-bottom: 1px solid $setting-border-color;
        background-color: $setting-header-bg;
        .help-block {
            margin-top: 15px;
            margin-bottom: 0;

            font-size: 13px;
            line-height: 22px;
            color: $setting-label-text;
        }
        .pdf-output {
            margin-top: 15px;
            padding-top: 15px;

            border-top: 1px solid $setting-separator-color;
            .pdf-output-row:first-child {
                a.delete-row {
                    top: 28px;
                }
            }
            .delete-row {
                position: absolute;
                top: 0;
                left: -35px;

                display: none;
                width: 34px;
                height: 34px;

                font-size: 20px;
                line-height: 34px;
                color: $setting-label-text;
                text-align: center;
                z-index: 2;
            }
        }
        .pdf-output-row {
            position: relative;
            &:hover {
                .delete-row {
                    display: block;
                    &:hover {
                        color: $brand-danger;
                    }
                }
            }
        }
        .row.no-overlap {
            margin-right: 5px;
        }
    }
    .template-editor {
        border-radius: 0 0 3px 3px;
        background-color: $setting-frame-bg;
    }
    .template-sidebar {
        float: left;
        width: 25%;

        font-size: 13px;
        color: $text-color;

        background-color: $setting-frame-bg;
        .sidebar-search {
            padding: 10px;

            border-bottom: 1px solid $setting-border-color;
        }
        li.open {
            .fa-folder:before {
                content: "\f07c";
            }
            .fa-folder-o:before {
                content: "\f115";
            }
        }
        .template-group {
            margin-bottom: -1px;
            ul {
                display: none;
            }
            li.open {
                ul {
                    display: block;
                }
            }
            > li {
                > a {
                    display: block;
                    padding: 0 10px;

                    font-weight: 600;
                    line-height: 30px;
                    color: $text-color;

                    border-bottom: 1px solid $setting-border-color;
                    &:hover {
                        background-color: darken($setting-frame-bg, 5%);
                        .fa {
                            &.fa-pencil {
                                opacity: 1;
                            }
                            &.pull-right {
                                &:hover {
                                    color: $text-color;
                                }
                            }
                        }
                    }
                    &:focus {
                        background-color: darken($setting-inner-frame-bg, 15%);
                        i.fa.fa-pencil {
                            opacity: 1;
                        }
                    }
                    &.has-active-element .fa-folder,
                    &.has-active-element .fa-folder-o {
                        color: $setting-active-text;
                    }
                    .fa {
                        margin-right: 5px;
                        color: $setting-label-text;
                        &.fa-pencil {
                            opacity: 0;
                            &.active,
                            &.active:hover {
                                opacity: 1;
                                color: $setting-active-text;
                            }
                            &:focus,
                            &.active:focus,
                            &.active:focus:hover {
                                opacity: 1;
                                color: darken($setting-active-text, 15%);
                                outline: none;
                            }
                        }
                        &.pull-right {
                            margin-right: 0;

                            line-height: inherit;
                        }
                    }
                }
            }
        }
        .template-frame {
            border-bottom: 1px solid $setting-border-color;
            background-color: $setting-inner-frame-bg;
            li {
                a {
                    display: block;
                    padding: 0 10px 0 30px;

                    font-weight: 600;
                    line-height: 40px;
                    color: $setting-inner-frame-text;
                    &.locked {
                        font-weight: 600;
                    }
                    &.active,
                    &:focus {
                        color: $setting-inverted-text;

                        background-color: $setting-active-bg;
                        &:hover {
                            color: $setting-inverted-text;
                            background-color: darken($setting-active-bg, 5%);
                            .fa {
                                color: $setting-inverted-text;
                            }
                        }
                        .fa {
                            color: $setting-inverted-text;
                        }
                    }
                    &:hover {
                        color: $setting-inner-frame-text;

                        background-color: darken($setting-inner-frame-bg, 5%);
                        .fa {
                            color: $setting-label-text;
                        }
                    }
                    .element-name {
                        width: 80%;
                        display: inline-block;
                        overflow: hidden;
                        vertical-align: top;
                        text-overflow: ellipsis;
                    }
                    .fa {
                        margin-right: 5px;
                        color: $setting-label-text;
                        &.fa-pencil {
                            opacity: 0;
                            &.active,
                            &.active:hover {
                                opacity: 1;
                                color: $setting-active-text;
                            }
                            &:focus {
                                opacity: 1;
                                color: $text-color;
                                outline: none;
                            }
                        }
                        &.pull-right {
                            margin-right: 0;

                            line-height: inherit;
                        }
                    }
                }
            }
        }
    }
    .template-settings {
        float: left;
        width: 75%;

        border-left: 1px solid $setting-border-color;
        background-color: $setting-bg;
    }

    .form-control-monospace {
        font-family: monospace;
        font-size: 13px;
        letter-spacing: 0.092em;
        word-break: break-all;
    }
    .form-control {
        background-color: $setting-input-bg;
        box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.05);
        &.disabled,
        &:disabled {
            background: $setting-input-bg-disabled;
            color: $input-border;
            @include placeholder($input-border);
        }
        &.inline-edit {
            margin: 10px 0;
            padding: 0 10px;
            font-size: 16px;
            font-weight: 600;
            line-height: 34px;
            background-color: $setting-input-highlighted-bg;
            display: inline-block;
            width: auto;
            height: 34px;
        }
        &.form-control-monospace {
            padding: 6px 8px 7px;
            background: transparent;
            position: relative;
            z-index: 1;
            + .form-control-monospace {
                position: absolute;
                bottom: 7px;
                left: 9px;
                max-width: calc(100% - 18px);
                color: transparent;
                display: inline-block;
                background: linear-gradient(90deg, transparent 7px, $setting-input-bg), linear-gradient(0deg, #999, #eee 1px);
                background-size: 9px 20px;
                padding-bottom: 2px;
                white-space: pre;
                br {
                    display: none;
                }
            }
        }
        &.form-multiline {
            height: auto;
        }
    }
    a.edit {
        display: none;
        margin-left: 5px;

        color: $setting-label-text;

        opacity: 0;
        &:hover {
            color: $setting-active-text;
        }
    }
    .radio,
    .checkbox,
    .input-list {
        label {
            -webkit-user-select: none;
               -moz-user-select: none;
                -ms-user-select: none;
                    user-select: none;
            margin-bottom: 5px;

            font-weight: normal;
            color: $text-color;
            text-transform: none;
            &.disabled {
                color: $setting-dimmed-text;
            }
        }
        &:hover {
            .edit {
                display: inline-block;

                opacity: 1;
                position: relative;
                top: -3px;
            }
            .edit-container {
                &.show + label + .edit {
                    display: none;
                    opacity: 0;
                }
            }
        }
    }
    .edit-container {
        display: none;
        float: left;
        .btn-success {
            height: 34px;
            line-height: 34px;
            padding: 0 12px;
            border: 0;
            margin: -4px 0 0 5px;
        }
    }

    .settings-header {
        min-height: 55px;
        padding: 0 20px;

        border-bottom: 1px solid $setting-border-color;
        .edit-container {
            margin-left: -11px;
            &.show:hover {
                background-color: $setting-bg;
            }
            &.show + h4,
            &.show + h4 + small {
                display: none;
            }
        }
        & > .checkbox {
            margin: 18px 0 0 0;
            label {
                font-weight: 600;
                color: $setting-dimmed-text;
            }
        }
        & > h4 {
            max-width: 75%;
            overflow: hidden;
            text-overflow: ellipsis;

            float: left;
            margin: 0;

            font-size: 16px;
            font-weight: 600;
            line-height: 54px;
            & + small {
                position: absolute;
                line-height: 56px;
                margin-left: 5px;
                font-weight: 600;
                color: $setting-dimmed-text;
            }
        }
        .editable-header {
            &:hover {
                background-color: $setting-input-highlighted-bg;
            }
        }
    }

    .settings-body {
        .edit-container {
            float: none;
            &.show + label {
                display: none;
            }
            .form-control {
                margin: -2px 0 0 0;
                font-size: 13px;
                font-weight: 100;
                line-height: 28px;
                width: 85%;
                height: 28px;
            }
            .btn {
                height: 28px;
                line-height: 28px;
                padding: 0px 9px;
                margin: -2px 0 0 5px;
            }
        }
        .sidebar-thumb-holder {
            height: auto;
            border: 1px solid $setting-border-color;
            border-radius: $thumbnail-border-radius;
            overflow: hidden;
            text-align: center;
            background: $setting-thumbnail-bg;
        }
        .pre-pos {
            margin-left: -5px;
        }
        .settings-group {
            padding: 30px 20px;

            border-bottom: 1px solid $setting-border-color;
            &:last-child {
                border: 0;
            }
            & > h5 {
                margin: 0;

                font-size: 14px;
                font-weight: 600;
                color: $setting-inner-frame-text;
            }
        }
        .settings-row {
            margin-top: 30px;
            .btn-group-first {
                margin-top: 29px;
            }
            .btn-toolbar {
                margin: 10px -5px 0;
                border: 1px solid $setting-border-color;
                background: $setting-inner-frame-bg;
                border-radius: $thumbnail-border-radius;
                .btn-group {
                    margin-top: 2px;
                    margin-bottom: 2px;
                    background: $body-bg;
                    border-radius: $thumbnail-border-radius;
                    &.btn-group-new-line {
                        clear: left;
                    }
                }
                .btn-sm {
                    padding: 4px 7px;
                    background: $setting-inner-frame-bg;
                    &.active {
                        box-shadow: inset 0 1px 0 rgba(0,0,0,0.125), inset 0 0 0 20px rgba(0,0,0,0.125);
                    }
                    &.btn-disabled:before {
                        content: '';
                        position: absolute;
                        background: $setting-inner-frame-bg;
                        opacity: 0.85;
                        width: 100%;
                        height: 100%;
                        z-index: 10;
                        margin: -4px -7px;
                    }
                }
            }
            .form-variable {
                background: $state-info-bg;
            }
        }
    }
    .select2 {
        width: 100% !important;
    }
    .select2-container--default {
        .select2-selection--single {
            background: $setting-bg;
            height: $input-height-base;
            .select2-selection__arrow {
                height: $input-height-base - 2px; /* height minus border */
            }
        }
        .select2-selection--single,
        .select2-selection--multiple {
            border-color: $setting-border-color;
            box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.05);
        }
        .select2-search--inline:only-child {
            .select2-search__field {
                padding: 1px 7px;
            }
        }
    }
    .select2-container--focus {
        .select2-selection--single {
            border-color: $setting-active-text;
        }
    }
    fieldset {
        &.checkboxgroup {
            label + .help-block {
                margin-top: 10px;
            }
            .radio label + .form-control {
                margin-top: 5px;
            }
            .select2-container {
                width: 100% !important;
                margin-top: 10px;
            }
        }
        &.subcheckboxgroup {
            margin: -15px 0 -5px 20px;
        }
        .form-group,
        .input-group {
            margin: -5px 0 15px;
        }
        .select-group {
            margin: -5px 0 20px 20px;
        }
        .form-control + .form-control,
        .form-control + .input-group-addon,
        .input-group-addon + .form-control {
            border-left: none;
        }
        &.input-list {
            margin: 0 0 10px 0;
            .input-group:only-child {
                margin-top: -8px;
                margin-bottom: 7px;
            }
        }
        & > label {
            margin-bottom: 0;
        }
        & > fieldset {
            margin-top: 15px;
            margin-bottom: 0;
        }
    }
    label {
        margin-bottom: 10px;

        font-size: 13px;
        font-weight: 600;
        color: $setting-label-text;
        text-transform: uppercase;
        &.pos-check {
            margin-bottom: 0;
        }
        .tooltip {
            text-transform: none;
        }
    }
    .positioning-block {
        position: relative;

        float: right;
        width: 34px;
        height: 34px;
        margin-top: 29px;
        margin-right: 9px;

        border: 1px solid $setting-border-color;
        background-color: $setting-bg;
        box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.05);
        input {
            position: absolute;
            opacity: 0;
            &:checked + label {
                border-color: $setting-active-bg;
                background-color: $setting-active-bg;
            }
            &:focus + label {
                outline: $setting-active-bg auto 5px;
            }
        }
        .pos-check {
            position: absolute;

            display: block;
            width: 8px;
            height: 8px;

            border: 1px solid $setting-border-color;
            background-color: $setting-bg;
            &.pos-tl {
                top: -4px;
                left: -4px;
            }
            &.pos-tc {
                top: -4px;
                left: 50%;

                margin-left: -4px;
            }
            &.pos-tr {
                top: -4px;
                right: -4px;
            }
            &.pos-bl {
                bottom: -4px;
                left: -4px;
            }
            &.pos-bc {
                bottom: -4px;
                left: 50%;

                margin-left: -4px;
            }
            &.pos-br {
                right: -4px;
                bottom: -4px;
            }
            &.pos-ml {
                top: 50%;
                left: -4px;

                margin-top: -4px;
            }
            &.pos-mc {
                top: 50%;
                left: 50%;

                margin-top: -4px;
                margin-left: -4px;
            }
            &.pos-mr {
                top: 50%;
                right: -4px;

                margin-top: -4px;
            }
            .checked {
                border-color: $setting-active-bg;
                background-color: $setting-active-bg;
            }
        }
    }
    .btn-dashed {
        width: 100%;
        border-color: $setting-label-text;
        color: $setting-label-text;
    }
}

.template-list {
    margin: 20px -15px 0;
    .thumbnail {
        height: 260px;
        padding: 0;
        overflow: hidden;
        &:hover, &:focus {
            .btn-group,
            .btn {
                opacity: 1;
            }
        }
        &.thumbnail-overview:hover,
        &.thumbnail-overview:focus {
            .fa:before {
                color: $setting-thumbnail-hover-text;
            }
        }
        &:hover {
            border-color: $setting-thumbnail-border-color;
            box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
            .thumbnail-image {
                background: $setting-thumbnail-bg;
            }
            .title {
                overflow: visible;
                span {
                    position: absolute;
                    white-space: normal;
                    background: $thumbnail-bg;
                    box-shadow: 0 5px 10px $thumbnail-bg;
                }
            }
            .dates {
                span {
                    color: $setting-thumbnail-hover-text;
                }
            }
        }
        > .btn-group,
        > .btn {
            position: absolute;
            margin: 4px;
            opacity: 0;
            &:hover, &:focus {
                opacity: 1;
            }
        }
        .btn-group {
            font-size: 0;
            z-index: 1; /* fix for unclickable buttons on placeholder thumbnail */
            .btn {
                padding: 2px 0;
                font-size: 14px;
                width: 28px;
            }
        }
        .btn {
            padding: 2px 7px 2px 6px;
            font-size: 16px;
        }
        .thumbnail-image {
            height: 190px;
            background: $setting-thumbnail-bg;
            overflow: hidden;
            text-align: center;
            line-height: 190px;
            -webkit-transition: background .2s ease-in-out;
            -moz-transition: background .2s ease-in-out;
            transition: background .2s ease-in-out;
            img {
                max-height: 100%;
                max-width: 100%;
                margin-top: -2px;
                box-shadow: 0 0 1px $setting-thumbnail-border-color;
            }
            .fa {
                display: block;
                margin: 0 auto;
                padding: 60px;
                color: $setting-thumbnail-text;
            }
        }
        .title {
            height: 20px;
            margin: 6px 9px 3px;
            position: relative;
            overflow: hidden;
            line-height: 1.45em;
            white-space: nowrap;
            text-overflow: ellipsis;
            span {
                width: 100%;
            }
        }
        .dates {
            margin: 0 9px;
            font-size: 11px;
            color: $setting-thumbnail-dimmed-text;
            .date {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}

.uploader-files {
    margin: 0;
    padding: 50px 15px;
    border: 4px dashed $breadcrumb-color;
    position: relative;
    border-radius: 5px;
    &.contains-error {
        .upload-status {
            color: $brand-danger;
        }
        .list-group-item {
            .progressbar span {
                background-color: $brand-danger;
            }
        }
    }
    &.success {
        .upload-status {
            color: $brand-success;
        }
        .list-group-item {
            .progressbar span {
                background-color: $brand-success;
            }
        }
    }
    .list-group-item {
        margin: 0;
        width: 100%;
        border: none;
        color: $breadcrumb-color;
        &.uploader-splashscreen {
            text-align: center;
            font-size: 26px;
            font-weight: 900;
        }
        &.uploader-container {
            position: absolute;
            background: #fff;
            top: 0;
            left: 0;
            bottom: 0;
            vertical-align: middle;
            .info-container {
                width: 100%;
                padding: 0 15px;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
            }
        }
        span {
            text-align: left;
            display: block;
            margin: 0 0 4px 0;
            color: $breadcrumb-color;
        }
        .filename {
            color: $text-color;
            font-weight: 700;
            font-size: 14px;
            margin: 4px 0 0 0;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
        .progressbar {
            margin: 10px 0 10px 0;
            height: 4px;
            border-radius: 2px;
            background-color: $breadcrumb-color;
            span {
                margin: 0;
                background-color: $brand-primary;
                height: 100%;
                border-radius: 2px;
            }
        }
    }
}
