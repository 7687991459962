.article-list.preview {
    border-bottom: 0;
    > li {
        padding: 10px 60px;

        border: 0;
        &.article-actions {
            border-top: 1px solid $gray-light;
        }
        &.article-main-image {
            margin-left: -2px;
            padding: 0 0 15px 0;
            figcaption {
                padding: 5px 62px;
            }
        }
        > h1, > h2 {
            margin: 10px 0;

            line-height: 1.3;
        }
        > p,
        blockquote {
            margin: 0;

            font-family: $font-family-serif;
            font-size: 15px;
            font-weight: 200;
            line-height: 1.8;
            + p {
                margin-top: 15px;
            }
            &.lead {
                font-size: 18px;
                font-weight: 200;
            }
        }
        blockquote {
            background-color: $gray-x-light;
        }
        .show-comments {
            position: relative;

            display: block;
            margin-top: 20px;
            padding: 10px 0;

            color: $gray-base;
            text-align: center;
            &:hover {
                color: $gray;
            }
            &:after,
            &:before {
                position: absolute;
                top: 50%;
                left: 0;

                width: 40%;
                height: 1px;

                background-color: $gray-light;

                content: '';
            }
            &:after {
                right: 0;
                left: auto;
            }
        }
        .add-comment {
            padding: 30px 0;

            border-top: 1px solid $gray-light;
            .form-group {
                margin-top: 0;
            }
            .form-control {
                border-color: $gray-light;
                background-color: #fff;
            }
        }
    }
    .lead {
        margin: 0;

        font-size: 20px;
        font-weight: 300;
        line-height: 1.6;
        color: lighten($text-color,25%);
        p,
        blockquote {
            font-size: 18px;
            font-weight: 200;
        }
    }
    blockquote {
        p {
            font-size: 20px;
            line-height: 1.6;
        }
    }
    .article-relation {
        display: block;
        padding: 10px 60px;

        color: lighten($text-color,25%);

        background-color: $gray-x-light;
        .fa {
            margin-right: 5px;
        }
    }
}

.page-header .article-relation {
    position: relative;
    top: -25px;

    display: block;
    padding: 10px 15px;

    color: lighten($text-color,25%);

    border: 1px solid rgba(0,0,0,0.1);
    border-top: 0;

    @include border-bottom-radius($border-radius-small);
    .fa {
        margin-right: 5px;
    }
}

.article-properties {
    padding: 25px;

    background-color: $gray-x-light;
    dt {
        margin-bottom: 5px;

        line-height: 20px;
    }
    &.dl-horizontal {
        dt,
        dd {
            width: 40%;
            margin: 0;

            line-height: 20px;
            text-align: left;
        }
        dd {
            width: 60%;
            margin-left: 40%;
        }
    }
}

.comment-list {
    margin: 0 -30px;
    padding: 20px 30px;
    .comment-list {
        padding-top: 15px;
        padding-bottom: 0;
    }
    p {
        margin: 0;

        font-family: $font-family-sans-serif;
        font-size: $font-size-base;
    }
    .media {
        margin: 0;
        padding: 15px 0;

        border-bottom: 0;
    }
    .comment-heading {
        margin-bottom: 3px;
    }
    .comment-body {
        margin-bottom: 5px;

        color: lighten($text-color,15%);
    }
    .comment-footer {
        font-size: 12px;
        time {
            color: $gray-base;
        }
    }
    .show-older-comments {
        position: relative;

        display: block;
        padding: 10px 0;

        text-align: center;

        border-top: 1px solid $gray-light;
        border-bottom: 1px solid $gray-light;
        &:hover {
            border-color: $gray-base;
            background-color: $gray-x-light;
        }
        &:after,
        &:before {
            position: absolute;
            top: 50%;
            left: 0;

            width: 35%;
            height: $line-height-computed;
            margin-top: -($line-height-computed/2);

            border-top: 1px solid $gray-light;
            border-bottom: 1px solid $gray-light;

            content: '';
        }
        &:after {
            right: 0;
            left: auto;
        }
    }
}
.cke.cke_float {
    z-index: 1029 !important;
}
