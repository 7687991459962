#mass-uploader-view h2 {
	margin-bottom:25px;
}

.mass-uploader-files > div {
	border-bottom:1px solid $gray-light;
}

.mass-uploader-files {
	.list-group-item {
		padding: 10px 15px;
		a {
			padding: 0;
			text-decoration: underline;
		}
	}

	.label {
		font-size:100%;
	}

	.upload-verified {
		color: #A9BB4D;
	}
}

.mass-uploader-dropzone {
    .placeholder-content {
        padding: 140px 40px;

        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        color: $gray-base;
        .fa {
            display: block;
            margin-bottom: 10px;

            color: $gray-base;
        }
        .btn {
            margin-top: 20px;
        }
    }
    &:hover {
        border-color: $brand-primary;

        cursor: pointer;
    }
}

#mass-uploader-pod {
    h1 {
        font-weight: 700;
        padding-top: 15%;
    }
    h2 {
        font-weight: 400;
        font-size: 24px;
    }
    a {
        text-decoration: underline;
    }
    p {
        color: $breadcrumb-color;
    }
    .mass-uploader-files {
        min-height: 100px;
        width: 75%;
        margin: 25px auto;
        position: relative;
        .mass-uploader-files-border {
            position: absolute;
            z-index: -1;
            border: 2px dashed $breadcrumb-color;
            margin: 0;
            border-radius: 5px;
            width: 100%;
            height: 100px;
            padding: 0;
        }
        li {
            background: #fff;
            border: 1px solid $breadcrumb-color;
            border-left: 5px solid $breadcrumb-color;
            height: 100px;
            border-radius: 3px;
            span {
                text-align: left;
                display: block;
                margin: 0 0 4px 59px;
                color: $breadcrumb-color;
                &.filename {
                    color: $text-color;
                    font-weight: 700;
                    font-size: 18px;
                    margin: 4px 0 0 59px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                }
                &.progressbar {
                    margin: 10px 0 10px 59px;
                    height: 4px;
                    border-radius: 2px;
                    background-color: $breadcrumb-color;
                    span {
                        margin: 0;
                        background-color: $brand-primary;
                        height: 100%;
                        border-radius: 2px;
                    }
                    &.contains-error span {
                        background-color: $brand-danger;
                    }
                }
            }
            i {
                color: $breadcrumb-color;
                font-size: 42px;
                float: left;
                margin: 18px 2px;
            }
        }
    }
}

#pod-processing-popup {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    min-height: 100vh;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 999999;
    .pod-processing-container {
        position: relative;
        margin: 200px auto 0 auto;
        height: 60%;
        min-height: 450px;
        min-width: 275px;
        width: 60%;
        background-color: #fff;
        border-radius: 5px;
        box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.3);
        p {
            position: absolute;
            color: $breadcrumb-color;
            font-size: 18px;
            bottom: 10%;
            padding: 0 10%;
            width: 100%;
            margin: 0;
        }
        .filename {
            color: $brand-primary;
            top: 10%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
        .contains-error {
            text-align: left;
            color: $gray;
            width: 50%;
            left: 25%;
            bottom: 20%;
            padding: 25px;
            border: 1px solid $breadcrumb-color;
            border-radius: 4px;
            font-size: 16px;
            max-height: 125px;
            overflow-y: auto;
        }
        .transfer-icon {
            top: 50%;
            transform: translateY(-50%);
            margin: 0 auto;
            width: 218px;
            height: 218px;
            border-width: 0;
            color: $brand-primary;
            .icon-file {
                top: 75px;
            }
            .icon-file:first-child {
                top: 55px;
            }
            .icon-file:nth-child(2) {
                top: 65px;
            }
        }
        .processing-error-icon, .processing-completed-icon {
            position: relative;
            transform: translateY(-50%);
            margin: 0 auto;
            width: 218px;
            height: 218px;
            border-radius: 100%;
            .fa {
                position: absolute;
                top: 50%;
                transform: translate(-50%, -50%);
                font-size: 60px;
            }
        }
        .processing-error-icon {
            top: 40%;
            border: 2px solid $brand-danger;
            color: $brand-danger;
        }
        .processing-completed-icon {
            top: 50%;
            color: $brand-success;
            border-color: $brand-success;
        }
        .transfer-icon:before,
        .processing-completed-icon:before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            border-style: solid;
            border-width: 2px;
            border-left-color: transparent;
            border-radius: 50%;
            left: 0;
            animation: fa-spin 1s infinite linear;
        }
        .try-again {
            font-size: 16px;
        }
    }
}

.modal-footer {
    padding: 20px 25px 25px;
    margin: 0;
    .btn {
        margin-top: 5px;
        vertical-align: top;
    }
}

@media(max-height: 1000px) {
    #pod-processing-popup .pod-processing-container {
        .filename {
            top: 5%;
        }
        .contains-error {
            bottom: 13%;
            padding: 10px;
            max-height: 115px;
        }
        .processing-error-icon {
            width: 175px;
            height: 175px;
            .fa {
                font-size: 42px;
            }
        }
        .try-again {
            bottom: 5%;
        }
    }
}

@media(max-width: 1000px) {
    #pod-processing-popup .pod-processing-container {
        .contains-error {
            width: 80%;
            left: 10%;
        }
    }
}
