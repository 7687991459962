// jQuery UI sortable (style for just created blocks)
.mutable-block-true {
    cursor: move;
}

.article-list {
    border-left: 2px solid $gray-light;
    padding-left: 0;
    list-style: none;
    > li {
        position: relative;

        padding: 15px 30px;

        border-bottom: 1px solid $gray-light;
        &.mutable-block-true {
            border-bottom: 0;
        }
        &.social:first-child {
            border-top: 1px solid $gray-light;
        }
        .cke_textarea_inline {
            background: $gray-x-light;
            border: 1px solid $gray-light;
            border-radius: $border-radius-small;
            padding: $padding-base-vertical $padding-base-horizontal;
        }
        .cke_focus {
            background: #fff;
        }
        .article-tools {
            position: absolute;
            top: 0;
            bottom: 0;
            left: -18px;
            z-index: 1;

            display: block;
            width: 34px;
            > .btn,
            .remove-article .btn {
                @include opacity(0);
                @include transition(opacity 0.1s ease-in);
                &.add {
                    @include opacity(1);
                }
            }
        }
        &.article-actions {
            min-height: 0;
            margin-top: -1px;

            border-top: 1px solid $gray-light;
            background-color: transparent;
            .pull-right {
                .btn {
                    margin-left: 5px;
                    .fa {
                        margin-right: 0.25em;
                    }
                }
            }
        }
        &:hover {
            .article-tools > .btn,
            .article-tools .remove-article .btn {
                @include opacity(1);
            }
        }
        h4 {
            display: inline-block;
            height: 34px;
            margin: 0;

            line-height: 34px;
            vertical-align: middle;
            small:before {
                content: '\0000a0';
            }
        }
        > .list-button {
            position: absolute;
            right: 30px;

            display: inline-block;

            color: #ccc;
            vertical-align: middle;
            &:hover {
                color: #999;
            }
        }
        .form-group,
        .thumbnail {
            margin-top: 15px;
        }
        &.mutable-block-palceholder {
            position: relative;

            height: 0;
            padding: 0;

            border: 0;
            &:after {
                position: absolute;
                top: -3px;
                right: 0;
                left: -2px;

                display: block;
                height: 6px;

                border: 2px solid $brand-primary;
                border-radius: $border-radius-small;
                background-color: $body-bg;

                content: '';
            }
        }
        &.ui-sortable-handle:hover {
            cursor: move;
        }
        &.ui-sortable-helper {
            border: 0;
            background-color: $body-bg;

            cursor: move;

            @include box-shadow(0 0 15px rgba(0,0,0,0.15), 0 0 0 1px rgba(0,0,0,0.15));
            @include opacity(0.75);
            @include scale(1.05);
            @include transition(transform 0.1s ease-in);
            .article-tools {
                @include opacity(0);
            }
        }
    }
}

.no-filter-results,
.no-artcles {
    width: 200px;
    margin: 10em auto;

    color: #999;
    .fa {
        position: absolute;

        margin-left: -1.2em;

        font-size: 3em;
        color: #ccc;
    }
    a {
        text-decoration: underline;
    }
}

.article-saving-status {
    position: relative;
    top: 1px;

    display: inline-block;
    padding: 0 12px;

    color: #bbb;
}

.article-tools {
    .popover-content {
        width: 182px;
        padding: 3px;
        .btn {
            min-width: 36px;
            padding-right: 0;
            padding-left: 0;
        }
    }
}

.remove-article {
    position: absolute;
    top: 50%;

    margin-top: -17px;
}

.add-article {
    position: absolute;
    bottom: 10px;
}

.preview-article-state {
    position: absolute;
    top: 10px;
}

.article-tools {
    > .btn,
    .button-group {
        margin-top: 10px;
    }
    .button-group {
        position: relative;
    }
}


.thumbnail {
    // A small plus in a dashed box for adding a new photo in social networks blocks
    // didn't changes its color sync to dashed border color change
    .fa {
        transition: color 0.2s ease-in-out;
    }
}

// Mixin and adjust the regular image class
.bynder-thumbnail {
    display: block;
    margin-bottom: 30px;
    padding: 0;

    line-height: $line-height-base;

    border: 1px solid rgba(0,0,0,0.1);
    border-radius: $thumbnail-border-radius;
    background-color: $thumbnail-bg;
    background-repeat: no-repeat;
    background-position: center;
    background-clip: padding-box;
    -webkit-background-size: cover;
       -moz-background-size: cover;
         -o-background-size: cover;
            background-size: cover;

    @include transition(border 0.2s ease-in-out);
    &:hover {
        border-color: $gray-light;
        .caption {
            @include opacity(1);
        }
    }
    .article-content {
        display: block;
        padding: 25px;
    }
    .article-content a {
        color: $text-color;
    }
    .btn {
        color: $gray-light;
        &:hover {
            color: $gray-base;
        }
    }
    .thumbnail-table {
        display: table;
        height: 230px;
        margin: 0 auto;

        text-align: center;
        .thumbnail-cell {
            display: table-cell;
            max-height: 100%;
            margin: 0 auto;

            text-align: center;
            vertical-align: middle;
            > img,
            a > img {
                max-height: 230px;
                margin-right: auto;
                margin-left: auto;

                @include img-responsive;
            }
        }
    }
    .article-image {
        position: relative;

        height: 300px;
        margin: -1px -1px 0 -1px;
        overflow: hidden;

        background-position: center center;
        -webkit-background-size: cover;
           -moz-background-size: cover;
             -o-background-size: cover;
                background-size: 100%;
                background-size: cover;

        @include border-top-radius($thumbnail-border-radius);
    }
    .article-content {
        position: relative;

        min-height: 100px;
        h1,
        h2 {
            margin-top: 0;
            margin-bottom: 10px;

            font-weight: 600;
        }
        p {
            margin-bottom: 0;

            font-family: $font-family-serif;

            @extend .lead;
            + p {
                margin-top: 10px;

                font-size: 14px;
            }
        }
        .lead {
            font-size: 18px;
            font-weight: 300;
            line-height: 1.6;
            color: lighten($text-color,25%);
        }
        > img {
            margin: 25px 0 0 0;
        }
        .article-relation {
            position: relative;
            top: -25px;
            left: -25px;

            display: inline-block;
            padding: 5px 25px;

            font-family: $font-family-sans-serif;
            color: lighten($text-color,25%);

            border-top-left-radius: $border-radius-small;
            border-bottom-right-radius: $border-radius-small;
            background-color: $gray-x-light;
            a {
                text-decoration: underline;
            }
            .fa {
                margin-right: 8px;
            }
        }
    }
    // [converter] extracted a&:hover, a&:focus, a&.active to a.thumbnail:hover, a.thumbnail:focus, a.thumbnail.active
    // Image captions
    .caption {
        position: relative;

        padding: 20px 25px;

        color: $thumbnail-caption-color;

        background-color: $thumbnail-caption-bg;

        @include border-bottom-radius($thumbnail-border-radius);
        @include transition(all 0.2s ease-in-out);
        h3,
        p {
            margin: 0;

            font-size: $font-size-base;
        }
        time,
        span {
            font-size: 14px;
            color: $gray-base;
        }
        &:after {
            position: absolute;
            top: 0;
            right: 25px;
            left: 25px;

            height: 1px;

            background-color: rgba(0,0,0,0.1);

            content: '';
        }
        &.caption-top:after {
            top: auto;
            bottom: 0;
        }
    }
    &.background-image {
        position: relative;

        border: 0;
        background-color: #444;
        .btn,
        .article-content > a {
            color: #fff;
        }
        .article-content {
            position: relative;
            z-index: 1;

            color: #fff;
            .lead {
                color: #fff;
            }
            .article-relation {
                color: #fff;

                background-color: rgba(0,0,0,0.15);
                a {
                    color: #fff;
                }
            }
        }
        .caption {
            z-index: 2;

            color: #fff;

            background-color: transparent;

            @include box-shadow(none);
            h3,
            p {
                margin: 0;

                font-size: $font-size-base;
            }
            .fa-squared {
                background-color: rgba(255,255,255,0.2);
            }
            time {
                color: #fff;
            }
            &:after {
                background-color: rgba(255,255,255,0.2);
            }
        }
        &:before {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1;

            border-radius: $thumbnail-border-radius;

            content: '';

            @include transition(all 0.2s ease-in-out);
            @include gradient-vertical($start-color: transparent,
            $end-color: rgba(0,0,0,0.5), $start-percent: 0, $end-percent: 100%);
        }
        &:hover:before {
            background-color: rgba(0,0,0,0.5);
        }
    }
}

.article-status {
    min-width: 200px;

    text-align: right;
    .fa-squared {
        margin-right: 0;

        vertical-align: middle;
    }
    .dropdown {
        display: inline-block;
    }
}

//
// Video controls
// --------------------------------------------------

$video-control-padding: 3px;

.video-thumbnail {
    padding: $video-control-padding;

    text-align: center;
    &:hover {
        .video-controls {
            @include scale(1);
            @include opacity(1);
        }
    }
    &:active {
        .btn-play {
            @include scale(1.75);
            @include opacity(0);
        }
    }
    .btn-play {
        position: absolute;
        top: 50%;
        left: 50%;

        width: 60px;
        height: 60px;
        margin-top: -31px;
        margin-left: -31px;
        padding: 0;

        font-size: 28px;
        line-height: 54px;
        color: #fff;

        border-radius: $border-radius-small;
        background-color: rgba(34,34,34,0.4);

        @include transition(opacity 0.2s ease-out, transform 0.3s ease-out);
        @include scale(1.1);
    }
    video {
        cursor: pointer;
    }
}

// if the block is smaller than 100% it's aligned on the center
.img-responsive {
    margin: 0 auto;
}

.vid-responsive {
    @extend .img-responsive;
}

.youtube-preview {
    display: block;
    margin: 0 auto;
}


.thumbnail > .video-controls {
    right: $video-control-padding;
    bottom: $video-control-padding;
    left: $video-control-padding;
}

video[role='video_player'] {
    cursor: pointer;
}


.video-controls {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;

    padding: 0 6px;

    background-color: rgba(34,34,34,0.8);

    @include transition(all 0.2s ease-in);
    @include opacity(0);
    .btn {
        min-width: 40px;

        color: rgba(255,255,255,0.75);
        &:hover,
        &.active {
            color: #fff;

            background-color: rgba(0,0,0,0.1);
        }
    }
    .open {
        .btn {
            background-color: rgba(0,0,0,0.2);
        }
    }
    .dropdown-menu {
        right: 0;

        min-width: 40px;

        background-color: rgba(34,34,34,0.8);
    }
    time {
        padding: 0 6px;

        font-size: 12px;
        color: #fff;
        .time-total {
            color: $gray-base;
            &:before {
                content: '/';
            }
        }
    }
    .progress {
        position: absolute;
        top: 50%;
        right: 52px;
        left: 190px;

        height: 4px;
        margin-top: -2px;
        overflow: visible;

        background-color: #111;
        .progress-bar {
            position: absolute;

            overflow: visible;

            background-color: rgba(255,255,255,0.5);
            .progress-control {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;

                width: 2px;
                box-sizing: content-box;

                border: 4px solid transparent;
                border: 0;
                border-radius: 50%;
                background-color: #fff;

                @include transition(all 0s ease-in);
            }
        }
        .progress-bar-loading {
            @include progress-bar-variant($progress-bar-loading-bg);
        }
    }
    &:hover {
        .progress {
            .progress-bar {
                .progress-control {
                    top: -8px;
                    right: -9px;
                    bottom: auto;

                    display: block;
                    width: 6px;
                    height: 6px;
                    box-sizing: content-box;

                    border: 7px solid #fff;
                    background-color: #ccc;

                    @include transition(all 0.1s ease-in);
                    &:hover {
                        background-color: $brand-primary;

                        cursor: pointer;
                    }
                }
            }
        }
    }
}

span.article-status-label {
    float: left;
    margin-right: 0.3em;

    text-transform: capitalize;
    &.article-status-published {
        color: $state-success-text;
    }
    &.article-status-final {
        color: $state-warning-text;
    }
    &.article-status-draft {
        color: $state-danger-text;
    }
}

.article-date {
    &.draft-date {
        @include svg-one-color($state-danger-text, 'transparent');
    }
    &.final-date {
        @include svg-one-color($state-warning-text, 'transparent');
    }
    &.published-date {
        @include svg-one-color($state-success-text, 'transparent');
    }
    &.range, &.active {
        @include svg-one-color(#FFF, 'transparent');
    }
    &.draft-date.final-date {
        @include svg-two-colors($state-warning-text, $state-danger-text, 'transparent');

        &.range, &.active {
            @include svg-two-colors(#FFF, #FFF, 'transparent');
        }
    }
    &.draft-date.published-date {
        @include svg-two-colors($state-success-text, $state-danger-text, 'transparent');

        &.range, &.active {
            @include svg-two-colors(#FFF, #FFF, 'transparent');
        }
    }
    &.final-date.published-date {
        @include svg-two-colors($state-success-text, $state-warning-text, 'transparent');

        &.range, &.active {
            @include svg-two-colors(#FFF, #FFF, 'transparent');
        }
    }
    &.final-date.published-date.draft-date {
        @include svg-three-colors($state-success-text, $state-warning-text, $state-danger-text, 'transparent');

        &.range, &.active {
            @include svg-three-colors(#FFF, #FFF, #FFF, 'transparent');
        }
    }
}

.article-calendar {
    text-align: center;
    .fa-refresh {
        font-size: 20px;
        color: #ccc;
        margin-bottom: 1.2em;
    }
    .datepicker {
        padding: 0 0 1.5em 0 !important;
        .datepicker-days {
            min-height: 320px !important;
        }
        table {
            width: 100% !important;
            tr {
                td,
                th {
                    height: 40px !important;
                }
                th.dow {
                    font-size: 12px;
                    font-weight: normal;
                    color: #999;
                    text-transform: uppercase;

                    border-bottom: 1px solid $gray-light;
                }
            }
            thead tr:first-child th {
                position: relative;

                height: 38px;

                font-size: 0;
                &.datepicker-switch {
                    font-size: 14px !important;
                    font-weight: normal;
                }
                &:after {
                    position: absolute;
                    top: 0;
                    right: 0;
                    left: 0;

                    width: auto;
                    height: 100%;

                    border: 1px solid #dcdcdc;
                    -webkit-border-radius: 20px;
                       -moz-border-radius: 20px;
                            border-radius: 20px;

                    content: '';
                }
                &.prev:after,
                &.next:after {
                    display: inline-block;
                    width: 40px;
                    height: 40px;

                    font: normal normal normal 16px/1 FontAwesome;
                    line-height: 40px;
                    color: #ccc;
                    text-align: center;

                    content: '\f053';

                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    text-rendering: auto;
                }
                &.datepicker-switch:after {
                    right: 10px !important;
                    left: 10px !important;
                    height: 40px;

                    -webkit-box-shadow: inset 0 1px 2px rgba(0,0,0,0.05);
                       -moz-box-shadow: inset 0 1px 2px rgba(0,0,0,0.05);
                            box-shadow: inset 0 1px 2px rgba(0,0,0,0.05);
                }
                &.next:after {
                    left: 10px;

                    content: '\f054';
                }
                &:hover {
                    background-color: transparent !important;
                    &:after {
                        color: #000;

                        border-color: #c1c0c0;
                    }
                }
            }
            tfoot {
                border-top: 1px solid #e5e5e5;
            }
        }
        .datepicker-months table,
        .datepicker-years table {
            thead tr:first-child th {
                &.next:after {
                    left: -10px;
                }
                &.datepicker-switch:after {
                    right: 20px !important;
                    left: 20px !important;
                }
            }
        }
    }
}

.article-publication-time {
    &:before {
        position: relative;
        top: 2px;

        padding-right: 0.2em;

        font-size: 20px;
        font-weight: 900;
        line-height: 1px;

        content: '\00B7';
    }
}
