
html.transfer,
body.transfer {
    height: 100%;
    margin: 0;
    padding: 0;
}

body.transfer {
    background-attachment: fixed;
    background-position: center center;
    -moz-background-size: cover;
         background-size: cover;
}

.transfer-container {
    height: 100%;

    font-size: 0;
    text-align: center;
    &:before {
        display: inline-block;
        height: 100%;

        vertical-align: middle;

        content: '';
    }
}

.transfer-box {
    display: inline-block;
    width: 320px;
    padding: 30px;

    font-size: 14px;
    text-align: center;
    vertical-align: middle;

    -webkit-border-radius: 3px;
       -moz-border-radius: 3px;
            border-radius: 3px;
    background-color: #fff;
    background-color: rgba(255,255,255,0.95);
    -webkit-box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.05);
       -moz-box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.05);
            box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.05);
    .transfer-logo {
        max-width: 100px;
        max-height: 100px;
        margin: 0 auto;

        text-align: center;
        img {
            max-width: 100px;
            max-height: 100px;
        }
    }
    p {
        margin: 0;

        font-size: 16px;
        line-height: 22px;
        color: #9ea4ae;
        word-wrap: break-word;

        -webkit-font-smoothing: antialiased;
    }
    a.btn {
        width: 160px;
        height: 42px;
        margin: 30px 0 0 0;
        padding: 0;

        font-size: 15px;
        font-weight: 400;
        line-height: 40px;

        border: 0;
    }
}

.transfer-icon {
    position: relative;

    width: 158px;
    height: 158px;
    margin: 30px auto;

    text-align: center;

    border: 2px solid $brand-primary;
    -webkit-border-radius: 100%;
       -moz-border-radius: 100%;
            border-radius: 100%;
    .icon-file {
        position: absolute;
        top: 45px;
        left: 50%;

        width: 65px;
        height: 84px;
        margin-left: -32.5px;

        font-size: 28px;
        line-height: 80px;
        color: $brand-primary;

        border: 2px solid $brand-primary;
        -webkit-border-radius: 4px;
           -moz-border-radius: 4px;
                border-radius: 4px;
        background-color: #fff;
        &:first-child {
            top: 25px;

            width: 48px;
            height: 61px;
            margin-left: -24px;
        }
        &:nth-child(2) {
            top: 35px;

            width: 57px;
            height: 73px;
            margin-left: -28.5px;
        }
    }
}

#transfer {
    .table {
        border: 1px solid #e7e9ed;
        -webkit-border-radius: 3px;
           -moz-border-radius: 3px;
                border-radius: 3px;

        border-collapse: separate;
        th {
            padding: 10px;

            font-size: 13px;
            font-weight: normal;
            color: #7f8fa4;

            border-bottom: 0;
            background: #f8fafc;
            & > i.fa {
                margin-left: 2px;
            }
            &.active-parent {
                .sort {
                    display: inline;
                }
                .sort.active {
                    display: none;
                }
            }
            .sort-desc {
                display: none;
            }
        }
        td {
            padding: 10px;

            font-size: 13px;

            border-top: 1px solid #e7e9ed;

            cursor: pointer;
            a {
                color: $text-color;
            }
            &.transfer-actions {
                .btn-group {
                    width: 80px;
                    margin: 0 -5px;
                    > a {
                        display: inline-block;
                        width: 15px;

                        color: #7f8fa4;
                        text-align: center;
                        &.btn {
                            padding: 2px 0 1px;
                            width: 27px;
                            color: #fff;
                            .popover {
                                color: $gray-base;
                                @include user-select(text);
                                .popover-title {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
            .tooltip {
                width: auto;
                white-space: nowrap;
            }
        }
        th > i.fa,
        td > i.fa {
            font-size: 14px;
        }
        .transfer-expired > td {
            color: #777;
        }
    }
    .transfer-status {
        display: block;
        width: 14px;
        height: 14px;
        margin: 2px auto 0 auto;

        -webkit-border-radius: 100%;
           -moz-border-radius: 100%;
                border-radius: 100%;
        background-color: #7f8fa4;
        &.transfer-active {
            background-color: $brand-success;
        }
        &.transfer-expired {
            background-color: $brand-danger;
        }
        &.transfer-expiring {
            background-color: $brand-warning;
        }
    }
    .transfer-actions {
        text-align: center;
    }
}
